import './App.css';
import Message from "./components/Message";

function App() {
  return (
    <div className="App">
      <Message/>
    </div>
  );
}

export default App;
