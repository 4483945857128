import {Spinner} from "react-bootstrap";
import {Card} from "react-bootstrap";
import Timer from "./Timer";

function Message(){
    return(
        <Card className="text-center">
            <Card.Header>We Are Undergoing Some Changes For 2023</Card.Header>
            <Card.Body>
                <Card.Title>Exciting Things Coming Soon</Card.Title>
                <Card.Text>
                    <h1><Spinner animation="grow" variant="info" size="md"/></h1>
                    <Timer/>
                </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted">Thank you for Visiting DPSS Group</Card.Footer>
        </Card>
    )
}

export default Message